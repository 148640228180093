import { request, noTimeOutReq } from '@/utils/request.js'

//展示所有路由api
export function getApiList (sysAuthApiId) {
  return request({
    url: `/sys/auth/api/${sysAuthApiId}/list`,
    method: 'post',
  })
}

//展示某个api及其所有子api
export function getApi (sysAuthApiId) {
  return request({
    url: `/sys/auth/api/${sysAuthApiId}/get`,
    method: 'post',
  })
}

//添加api
export function addApi (route) {
  return request({
    url: `/sys/auth/api/add`,
    method: 'post',
    data: route,
  })
}

//修改api
export function updateApi (route) {
  return request({
    url: `/sys/auth/api/update`,
    method: 'post',
    data: route,
  })
}

//删除api
export function removeApi (idList) {
  return request({
    url: `/sys/auth/api/delete`,
    method: 'post',
    data: idList,
  })
}
